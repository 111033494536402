"use client";
import { convertTextToValidURL } from "@whitelabel-engine/features/utils/format";
import {
  type Campaign,
  type CampaignTierStatus,
  CampaignTierStatusEnum,
} from "@whitelabel-engine/typings";
import LoadingIcon from "@whitelabel-engine/ui/Icon/Loading";
import type { ErcLabelProps } from "@whitelabel-engine/ui/Label/ErcLabel";
import type { SelectProps } from "@whitelabel-engine/ui/Select";
import { Select, SelectItem } from "@whitelabel-engine/ui/Select";
import TabList from "@whitelabel-engine/ui/TabList";
import Tab from "@whitelabel-engine/ui/TabList/Tab";
import TabPanel from "@whitelabel-engine/ui/TabList/TabPanel";
import Text from "@whitelabel-engine/ui/Text";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import type { FontTransform } from "@whitelabel-engine/ui/utils/fonts";
import type { BorderSize } from "@whitelabel-engine/ui/utils/sizes";
import { getWindow } from "@whitelabel-engine/ui/utils/window";
import { useRouter } from "next/navigation";
import { usePathname, useSearchParams } from "next/navigation";
import type { FC, PropsWithChildren } from "react";
import { useEffect, useRef, useState } from "react";

import IconRender from "../IconRender";
import Link from "../Link";
import ProjectCardInfo from "../ProjectCard/ProjectCardInfo";

const possibleStatus = ["all", ...Object.values(CampaignTierStatusEnum)] as (
  | CampaignTierStatus
  | "all"
)[];

const cleanAndCapitalizeString = (value: string) =>
  value
    ?.replace?.("_", " ")
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

type CampaignListProps = {
  data: Campaign[];
  status?: CampaignTierStatus | "all";
  category?: string;
  categories?: string[];
  categoryTransform?: FontTransform;
  ercTypeColor?: Colors;
  ercBorder?: BorderSize;
  ercBorderColor?: Colors;
  ercLabelType?: ErcLabelProps["type"];
  onTabChange?: (status: string, category: string) => void;
  onOptionChange?: (status: string, category: string) => void;
};

const CustomProjectCardInfo: FC<{
  campaign: Campaign;
  categoryTransform?: FontTransform;
  ercTypeColor?: Colors;
  ercBorderColor?: Colors;
  ercLabelType?: ErcLabelProps["type"];
  ercBorder?: BorderSize;
}> = ({
  campaign,
  categoryTransform,
  ercTypeColor = "neutral",
  ercBorderColor,
  ercBorder = "border-0",
  ercLabelType = "rounded",
}) => {
  return (
    <Link
      href={campaign.isEnabled ? convertTextToValidURL(campaign?.name) : ""}
    >
      <ProjectCardInfo
        backgroundImage={campaign?.banner}
        backgroundColor="overlay"
        borderColor="neutral"
        categories={campaign.categories ?? []}
        color="light"
        className={`bg-overlay border-neutral rounded-3xl ${
          campaign.isEnabled ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        contentClassName="bg-transparent border-transparent sm:bg-overlay sm:border-neutral"
        description={campaign.description}
        // @ts-expect-error
        ercType={campaign?.ercType}
        ercTypeColor={ercTypeColor}
        categoriesColor={ercTypeColor}
        image={campaign?.logo}
        imageAlt={campaign.name}
        name={campaign.name}
        // @ts-expect-error
        status={campaign?.status}
        categoryTransform={categoryTransform}
        ercBorder={ercBorder}
        ercBorderColor={ercBorderColor}
        ercLabelType={ercLabelType}
      />
    </Link>
  );
};

const EmptyState = ({ timeout = 200 }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      getWindow().setTimeout(() => {
        ref?.current?.classList?.add?.("opacity-100");
      }, timeout);
    }
  }, [ref.current]);

  return (
    <div
      ref={ref}
      className="flex w-full flex-col items-center justify-center gap-2 opacity-0 transition-opacity duration-1000 sm:min-h-[500px]"
    >
      <IconRender icon="Database" color="disabled" width={42} />
      <Text variant="h4" className="!font-bold">
        No data
      </Text>
      <Text variant="span" family="serif" className="text-center">
        Oops... No projects found for your filters.
      </Text>
    </div>
  );
};

const LoadingComponent: FC<PropsWithChildren<{ showLoader?: boolean }>> = ({
  children,
  showLoader = false,
}) => {
  if (showLoader) {
    return (
      <div className="flex min-h-[500px] w-full flex-col items-center justify-center gap-2">
        <LoadingIcon />
      </div>
    );
  }

  return children;
};

const CategoriesSelect: FC<
  SelectProps & { categories?: string[]; fullWidth?: boolean }
> = ({ className, value, onValueChange, categories = ["all"], fullWidth }) => {
  return (
    <Select
      color="light"
      className={className}
      value={value}
      onValueChange={onValueChange}
      fullWidth={fullWidth}
    >
      {categories.map((option: string) => (
        <SelectItem
          key={option}
          value={option}
          className="bg-overlay font-bold"
        >
          {cleanAndCapitalizeString(
            option === "all" ? "all categories" : option,
          )}
        </SelectItem>
      ))}
    </Select>
  );
};

const CampaignList: FC<CampaignListProps> = ({
  data,
  status = "all",
  category = "all",
  categories = [],
  onTabChange,
  onOptionChange,
  categoryTransform,
  ercTypeColor,
  ercBorderColor,
  ercBorder = "border-0",
  ercLabelType = "rounded",
}) => {
  const [statusValue, setStatus] = useState(
    possibleStatus?.indexOf?.(status ?? "all"),
  );
  const [isChangingRoute, setIsChangingRoute] = useState(false);
  const [selectOption, setSelectOption] = useState(category);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const hasAllData = status === "all" && data?.length;
  const hasLiveData = status === CampaignTierStatusEnum.live && data?.length;
  const hasUpcomingData =
    status === CampaignTierStatusEnum.upcoming && data?.length;
  const hasCompletedData =
    status === CampaignTierStatusEnum.completed && data?.length;
  const hasOnVoteData =
    status === CampaignTierStatusEnum.on_vote && data?.length;

  const handleOnChange = (status: string, category: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set("status", status);
    searchParams.set("category", category);

    setIsChangingRoute(true);

    router.push(`?${searchParams}`, { scroll: false });
  };

  const handleSelectOnChange = (value: string) => {
    setSelectOption(value);
    if (onOptionChange) {
      onOptionChange?.(possibleStatus?.[statusValue], value);
      return;
    }
    handleOnChange?.(possibleStatus?.[statusValue], value);
  };

  const handleTabChange = (index: number) => {
    setStatus(index);
    if (onTabChange) {
      onTabChange?.(possibleStatus?.[index] ?? "all", selectOption ?? "all");
      return;
    }
    handleOnChange?.(possibleStatus?.[index] ?? "all", selectOption ?? "all");
  };

  useEffect(() => {
    setIsChangingRoute(false);
  }, [pathname, searchParams]);

  return (
    <>
      <TabList
        indicatorColor="disabled"
        textColor="light"
        hoverTextColor="light"
        value={statusValue}
        onChange={handleTabChange}
        tabListBorderColor="transparent"
        suffixContent={
          <CategoriesSelect
            className="bg-overlay hidden w-44 min-w-44 font-bold sm:flex"
            value={selectOption}
            categories={categories}
            onValueChange={handleSelectOnChange}
          />
        }
      >
        {possibleStatus.map((el) => (
          <Tab key={el}>{cleanAndCapitalizeString(el)}</Tab>
        ))}
      </TabList>

      <CategoriesSelect
        className="bg-overlay mb-4 flex font-bold sm:hidden"
        value={selectOption}
        categories={categories}
        onValueChange={handleSelectOnChange}
        fullWidth
      />

      <TabPanel
        value={statusValue}
        index={0}
        className={`flex flex-col gap-4 ${
          hasAllData ? "sm:grid sm:grid-cols-2" : ""
        }`}
      >
        <LoadingComponent showLoader={isChangingRoute}>
          {hasAllData ? (
            data.map((campaign) => (
              <CustomProjectCardInfo
                ercTypeColor={ercTypeColor}
                categoryTransform={categoryTransform}
                key={campaign.id}
                campaign={campaign}
                ercBorder={ercBorder}
                ercBorderColor={ercBorderColor}
                ercLabelType={ercLabelType}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </LoadingComponent>
      </TabPanel>

      <TabPanel
        value={statusValue}
        index={1}
        className={`flex flex-col gap-4 ${
          hasLiveData ? "sm:grid sm:grid-cols-2" : ""
        }`}
      >
        <LoadingComponent showLoader={isChangingRoute}>
          {hasLiveData ? (
            data.map((campaign) => (
              <CustomProjectCardInfo
                categoryTransform={categoryTransform}
                key={campaign.id}
                campaign={campaign}
                ercTypeColor={ercTypeColor}
                ercBorder={ercBorder}
                ercBorderColor={ercBorderColor}
                ercLabelType={ercLabelType}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </LoadingComponent>
      </TabPanel>

      <TabPanel
        value={statusValue}
        index={2}
        className={`flex flex-col gap-4 ${
          hasUpcomingData ? "sm:grid sm:grid-cols-2" : ""
        }`}
      >
        <LoadingComponent showLoader={isChangingRoute}>
          {hasUpcomingData ? (
            data.map((campaign) => (
              <CustomProjectCardInfo
                ercTypeColor={ercTypeColor}
                categoryTransform={categoryTransform}
                key={campaign.id}
                campaign={campaign}
                ercBorder={ercBorder}
                ercBorderColor={ercBorderColor}
                ercLabelType={ercLabelType}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </LoadingComponent>
      </TabPanel>

      <TabPanel
        value={statusValue}
        index={3}
        className={`flex flex-col gap-4 ${
          hasCompletedData ? "sm:grid sm:grid-cols-2" : ""
        }`}
      >
        <LoadingComponent showLoader={isChangingRoute}>
          {hasCompletedData ? (
            data.map((campaign) => (
              <CustomProjectCardInfo
                ercTypeColor={ercTypeColor}
                categoryTransform={categoryTransform}
                key={campaign.id}
                campaign={campaign}
                ercBorder={ercBorder}
                ercBorderColor={ercBorderColor}
                ercLabelType={ercLabelType}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </LoadingComponent>
      </TabPanel>

      <TabPanel
        value={statusValue}
        index={4}
        className={`flex flex-col gap-4 ${
          hasOnVoteData ? "sm:grid sm:grid-cols-2" : ""
        }`}
      >
        <LoadingComponent showLoader={isChangingRoute}>
          {hasOnVoteData ? (
            data.map((campaign) => (
              <CustomProjectCardInfo
                ercTypeColor={ercTypeColor}
                categoryTransform={categoryTransform}
                key={campaign.id}
                campaign={campaign}
                ercBorder={ercBorder}
                ercBorderColor={ercBorderColor}
                ercLabelType={ercLabelType}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </LoadingComponent>
      </TabPanel>
    </>
  );
};
export default CampaignList;
