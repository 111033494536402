import type { CampaignTierStatus } from "@whitelabel-engine/typings";
import type { CampaignContractErcType } from "@whitelabel-engine/typings/contract";
import Label from "@whitelabel-engine/ui/Label";
import type { ErcLabelProps } from "@whitelabel-engine/ui/Label/ErcLabel";
import ErcLabel from "@whitelabel-engine/ui/Label/ErcLabel";
import type { LabelProps } from "@whitelabel-engine/ui/Label/type";
import Text from "@whitelabel-engine/ui/Text";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import type { FontTransform } from "@whitelabel-engine/ui/utils/fonts";
import type { BorderSize } from "@whitelabel-engine/ui/utils/sizes";
import type { ForwardedRef } from "react";
import { forwardRef, type HTMLAttributes, useMemo } from "react";
import { tv } from "tailwind-variants";

import StatusLabel from "../StatusLabel";
import { addEllipsisInText } from "../utils/format";

export type ProjectCardInfoProps = HTMLAttributes<HTMLDivElement> & {
  imageBackgroundColor?: Colors;
  imageBorderColor?: Colors;
  backgroundColor?: Colors;
  backgroundImage?: string;
  borderColor?: Colors;
  borderRadius?: string;
  categories?: string[];
  categoriesColor?: Colors;
  contentClassName?: string;
  contentBackgroundColor?: Colors;
  contentBorderColor?: Colors;
  description: string;
  descriptionTrimLength?: number;
  ercType?: CampaignContractErcType;
  ercTypeColor?: Colors;
  image?: string;
  imageAlt?: string;
  name: string;
  status: CampaignTierStatus;
  statusSize?: LabelProps["size"];
  categoryTransform?: FontTransform;
  ercBorder?: BorderSize;
  ercBorderColor?: Colors;
  ercLabelType?: ErcLabelProps["type"];
};

const projectCardInfo = tv({
  base: "flex flex-col",
  slots: {
    wrapper: [
      "after:absolute",
      "after:bg-gradient-to-b",
      "after:bottom-0",
      "after:content-['']",
      "after:from-transparent",
      "after:inset-x-0",
      "after:rounded-xl",
      "after:to-background",
      "after:top-[20%]",
      "after:w-full",
      "after:z-0",
      "bg-center",
      "bg-cover",
      "border-2",
      "border-primary",
      "border-solid",
      "flex-col",
      "flex",
      "h-fit",
      "min-w-[300px]",
      "overflow-hidden",
      "p-4",
      "relative",
    ],
    top: "z-[1] mb-40 flex w-full flex-row justify-between sm:mb-52",
    bottom: [
      "sm:after:absolute",
      "sm:after:bg-gradient-to-b",
      "sm:after:bottom-0",
      "sm:after:content-['']",
      "sm:after:from-transparent",
      "sm:after:inset-x-0",
      "sm:after:rounded-xl",
      "sm:after:to-background",
      "sm:after:top-[30%]",
      "sm:after:w-full",
      "sm:after:z-[-1]",
      "relative",
      "flex-col",
      "flex",
      "gap-2",
      "rounded-xl",
      "sm:border-2",
      "sm:border-solid",
      "sm:pb-4",
      "sm:px-4",
      "w-full",
      "z-[1]",
    ],
    imageWrapper: "relative flex h-6 sm:h-12",
    image:
      "border-primary absolute bottom-0 left-0 h-12 w-12 rounded-full border-2 border-solid sm:h-20 sm:w-20",
  },
});

const ProjectCard = forwardRef(function ProjectCard(
  {
    backgroundImage,
    categories,
    categoriesColor,
    className = "",
    contentClassName = "",
    description,
    descriptionTrimLength = 100,
    ercType,
    ercTypeColor,
    ercBorder = "border-0",
    ercBorderColor = "light",
    ercLabelType = "rounded",
    image,
    imageAlt = "",
    imageBackgroundColor,
    imageBorderColor,
    name,
    status,
    statusSize,
    categoryTransform,
  }: ProjectCardInfoProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const _description = addEllipsisInText(description, descriptionTrimLength);

  const {
    wrapper,
    top,
    bottom,
    image: imageClassName,
    imageWrapper,
  } = useMemo(() => {
    return projectCardInfo();
  }, []);

  return (
    <div
      ref={ref}
      className={wrapper({ className })}
      style={{
        backgroundImage: `url(${backgroundImage ?? ""})`,
      }}
    >
      <div className={top()}>
        <div className="flex justify-start gap-2">
          {categories?.map?.((item, index) => (
            <Label
              transform={categoryTransform}
              color={categoriesColor}
              key={`${item}-${index}`}
            >
              {item}
            </Label>
          ))}
        </div>
        <StatusLabel size={statusSize} status={status} />
      </div>

      <div
        className={bottom({
          className: contentClassName,
        })}
      >
        <div className={imageWrapper()}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={imageClassName({
              className: `bg-${imageBackgroundColor} border-${imageBorderColor}`,
            })}
            src={image}
            alt={imageAlt}
          />
          <Text className="ml-14 block !font-bold sm:hidden" variant="h5">
            {name}
          </Text>
        </div>
        <Text className="hidden sm:block" variant="h3">
          {name}
        </Text>
        <Text variant="p" family="serif" className="min-h-[48px]">
          {_description}
        </Text>
        <div className="min-h-[28px]">
          {ercType ? (
            <ErcLabel
              border={ercBorder}
              borderColor={ercBorderColor}
              color={ercTypeColor}
              type={ercLabelType}
              className="font-bold"
            >
              {ercType}
            </ErcLabel>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default ProjectCard;
