import type { FC } from "react";

import _BlankIcon from "./_Blank";
import type { IconProps } from "./utils";

const ArrowOutlineDownIcon: FC<IconProps> = (props) => {
  return (
    <_BlankIcon viewBox="0 0 18 9" {...props}>
      <path d="M0.549748 0.419621C0.842641 0.126728 1.31752 0.126728 1.61041 0.419621L8.13041 6.93962C8.60752 7.41673 9.39264 7.41673 9.86975 6.93962L16.3897 0.419621C16.6826 0.126728 17.1575 0.126728 17.4504 0.419621C17.7433 0.712514 17.7433 1.18739 17.4504 1.48028L10.9304 8.00028C9.86751 9.06318 8.13264 9.06318 7.06975 8.00028L0.549748 1.48028C0.256855 1.18739 0.256855 0.712514 0.549748 0.419621Z" />
    </_BlankIcon>
  );
};

export default ArrowOutlineDownIcon;
