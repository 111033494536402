"use client";

import { t } from "@whitelabel-engine/i18n";
import type { ButtonProps } from "@whitelabel-engine/ui/Button";
import Text from "@whitelabel-engine/ui/Text";
import { type Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC, type PropsWithChildren } from "react";

import DynamicButton from "../Dynamic/Button";
import IconRender from "../IconRender";
import Link from "../Link";
import { addEllipsisInText } from "../utils/format";

export type LiveProjectSimpleBannerProps = {
  backgroundColor?: Colors;
  image?: string;
  color?: Colors;
  description?: string;
  name?: string;
  className?: string;
  href?: string;
  trimDescriptionCount?: number;
  viewDetailsButtonColor?: Colors;
  viewDetailsButtonVariant?: ButtonProps["variant"];
  viewDetailsButtonIconColor?: Colors;
};

const LiveProjectSimpleBanner: FC<
  PropsWithChildren<LiveProjectSimpleBannerProps>
> = ({
  backgroundColor = "background",
  image,
  className,
  color = "light",
  description = "",
  name,
  href = "",
  trimDescriptionCount = 100,
  viewDetailsButtonColor = "overlay",
  viewDetailsButtonVariant,
  viewDetailsButtonIconColor = "light",
}) => {
  const _description = addEllipsisInText(description, trimDescriptionCount);
  return (
    <div
      className={[
        "relative",
        "after:absolute",
        "after:bg-gradient-to-b",
        "after:bottom-0",
        "after:content-['']",
        "after:from-transparent",
        "after:inset-x-0",
        "after:to-background",
        "after:top-[30%]",
        "after:w-full",
        "after:z-0",
        "after:rounded-xl",
        "after:sm:border-none",
        "after:sm:rounded-none",
        "bg-center",
        "bg-cover",
        "flex-col",
        "flex",
        "justify-end",
        "min-h-[330px]",
        "items-start",
        "p-4",
        "relative",
        "rounded-xl",
        "sm:rounded-none",
        "sm:min-h-[560px]",
        "w-full",
        `text-${color}`,
        `bg-${backgroundColor}`,
        className,
      ].join(" ")}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div
        className={[
          "relative",
          "z-[1]",
          "flex",
          "flex-row",
          "items-end",
          "justify-between",
          "w-full",
          "max-w-[1440px]",
          "mx-auto",
          "px-4",
        ].join(" ")}
      >
        <div className="flex w-full flex-col justify-end sm:w-6/12 sm:gap-8">
          <Text className="w-fit text-xl sm:text-4xl" variant="h2">
            {name}
          </Text>
          <Text className="w-fit" variant="p" family="serif">
            {_description}
          </Text>
        </div>

        <Link href={href} className="hidden sm:flex">
          <DynamicButton
            color={viewDetailsButtonColor}
            variant={viewDetailsButtonVariant}
            className="!font-sans font-bold"
          >
            {t`View Details`}{" "}
            <IconRender
              icon="ArrowSquaredRight"
              color={viewDetailsButtonIconColor}
              className="w-4"
            />
          </DynamicButton>
        </Link>
      </div>
    </div>
  );
};

export default LiveProjectSimpleBanner;
