import * as SelectPrimitive from "@radix-ui/react-select";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import ArrowOutlineDownIcon from "../Icon/ArrowOutlineDown";
import { cn } from "../utils/cn";

const selectStyles = tv({
  variants: {
    error: {
      true: "border-danger",
    },
    focus: { true: "border-primary" },
    disabled: { true: "border-neutral" },
    fullWidth: {
      true: {
        base: "w-full",
        content: "w-full",
      },
    },
    size: {
      small: "text-sm",
      medium: "text-base",
      large: "text-lg",
    },
    color: {
      light: {
        base: "text-light border-neutral",
        content: "bg-overlay border-neutral text-light",
      },
      primary: {
        base: "text-primary border-primary",
        content: "bg-primary border-primary text-light",
      },
      secondary: {
        base: "text-secondary border-secondary",
        content: "bg-secondary border-secondary text-light",
      },
    },
  },
  slots: {
    base: "relative flex justify-center",
    content:
      "relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border shadow-md",
    trigger:
      "border-neutral flex w-full items-center justify-between gap-2 whitespace-nowrap rounded border-2 border-solid px-4 py-2 outline-0",
  },
});

type SelectVariants = VariantProps<typeof selectStyles>;

export interface SelectProps
  extends SelectVariants,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Root> {
  className?: string;
  "data-testid"?: string;
}

const Select = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  SelectProps
>(function Select(
  {
    children,
    size = "medium",
    color = "primary",
    className,
    fullWidth,
    ...props
  },
  ref,
) {
  const { base, content, trigger } = selectStyles({
    size,
    color,
    fullWidth,
  });
  return (
    <div className={cn(base(), className)}>
      <SelectPrimitive.Root data-testid={props?.["data-testid"]} {...props}>
        <SelectPrimitive.Trigger className={trigger()} ref={ref}>
          <SelectPrimitive.SelectValue />
          <SelectPrimitive.Icon asChild>
            <ArrowOutlineDownIcon width={14} color={color} />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content className={content()}>
            <SelectPrimitive.Viewport className="p-1">
              {children}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </div>
  );
});
Select.displayName = "Select";

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <SelectPrimitive.Item
      className={cn(
        "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className,
      )}
      ref={ref}
      {...props}
    >
      <SelectPrimitive.ItemText>{props.children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = "SelectItem";

export { Select, SelectItem };
